import { css } from "@emotion/react";
import colours from "./colours";

// Frutiger LT Pro 45 Light (font-weight: 300)
import FrutigerLT45LightEot from "../fonts/FrutigerLTPro45Light.eot";
import FrutigerLT45LightTtf from "../fonts/FrutigerLTPro45Light.ttf";
import FrutigerLT45LightWoff from "../fonts/FrutigerLTPro45Light.woff";
import FrutigerLT45LightWoff2 from "../fonts/FrutigerLTPro45Light.woff2";

// Frutiger LT Pro 55 Roman (font-weight: 400)
import FrutigerLT55RomanEot from "../fonts/FrutigerLTPro55Roman.eot";
import FrutigerLT55RomanTtf from "../fonts/FrutigerLTPro55Roman.ttf";
import FrutigerLT55RomanWoff from "../fonts/FrutigerLTPro55Roman.woff";
import FrutigerLT55RomanWoff2 from "../fonts/FrutigerLTPro55Roman.woff2";

// Frutiger LT Pro 65 Bold (font-weight: 700)
import FrutigerLT65LightEot from "../fonts/FrutigerLTPro65Bold.eot";
import FrutigerLT65LightTtf from "../fonts/FrutigerLTPro65Bold.ttf";
import FrutigerLT65LightWoff from "../fonts/FrutigerLTPro65Bold.woff";
import FrutigerLT65LightWoff2 from "../fonts/FrutigerLTPro65Bold.woff2";

export const fontImports = css`
    // Frutiger LT Pro 45 Light (font-weight: 300)
    @font-face {
        font-family: "Frutiger";
        src: url("${FrutigerLT45LightEot}?#iefix");
        src: url("${FrutigerLT45LightEot}?#iefix") format("eot"), url("${FrutigerLT45LightWoff2}") format("woff2"), url("${FrutigerLT45LightWoff}") format("woff"), url("${FrutigerLT45LightTtf}") format("truetype");
        font-weight: 300;
    }

    // Frutiger LT Pro 55 Roman (font-weight: 400)
    @font-face {
        font-family: "Frutiger";
        src: url("${FrutigerLT55RomanEot}?#iefix");
        src: url("${FrutigerLT55RomanEot}?#iefix") format("eot"), url("${FrutigerLT55RomanWoff2}") format("woff2"), url("${FrutigerLT55RomanWoff}") format("woff"), url("${FrutigerLT55RomanTtf}") format("truetype");
        font-weight: 400;
    }

    // Frutiger LT Pro 65 Bold (font-weight: 700)
    @font-face {
        font-family: "Frutiger";
        src: url("${FrutigerLT65LightEot}?#iefix");
        src: url("${FrutigerLT65LightEot}?#iefix") format("eot"), url("${FrutigerLT65LightWoff2}") format("woff2"), url("${FrutigerLT65LightWoff}") format("woff"), url("${FrutigerLT65LightTtf}") format("truetype");
        font-weight: 700;
    }
`;

export const fonts = {
    frutiger: '"Frutiger", "helvetica neue", helvetica, arial, sans-serif',
    primary: '"Frutiger", "helvetica neue", helvetica, arial, sans-serif',
    arial: 'arial, sans-serif',
    baseSize: "14px",
    buttonBaseSize: "18px",
    colour: colours.grey[900],
    lineHeight: 1.5,
    weights: {
        base: 400,
        thin: 100,
        light: 300,
        normal: 400, // regular, roman
        medium: 500,
        semiBold: 600,
        bold: 700,
        extraBold: 800,
        bolder: 'bolder'
    },
};
