import React, { useCallback, useState } from 'react';
import { css } from "@emotion/react";

import SocialButton from "../auth/SocialButton";
import { SocialLoginType } from "../../includes/auth";
import ErrorMessage from "../errors/ErrorMessage";

type Props = {
    socialSignUp: (socialUser: any, socialLoginType: SocialLoginType) => void,
    buttonWidth?: number,
    isSignUp?: boolean
};

const SocialLogin: React.FC<Props> = ({ isSignUp = false, socialSignUp, buttonWidth = 400 }) => {
    const [hasSocialLoginFailure, setHasSocialLoginFailure] = useState(false);

    const handleGoogleLogin = useCallback((socialUser: any) => {
        socialSignUp(socialUser, 'google');
    }, [socialSignUp]);

    /**
     * The error could be one of the following list:
     * User already connected
     * Fetching user
     * SDK not loaded
     * Unknown error
     */
    const handleLoginFailure = (error: any) => {
        setHasSocialLoginFailure(true);
        console.log('handleSocialLoginFailure', error);
    }

    return (
        <>
            <ErrorMessage isVisible={hasSocialLoginFailure} css={errorMessageStyles}>
                We couldn’t load social login. Make sure to enable third-party cookies.
            </ErrorMessage>
            <SocialButton
                triggerLogin={handleGoogleLogin}
                buttonWidth={buttonWidth}
                handleLoginFailure={handleLoginFailure}
                isSignUp={isSignUp}
            />
        </>
    );
};

const errorMessageStyles = css`
    margin-top: 16px;
    margin-bottom: 16px;
`;

export default SocialLogin;
