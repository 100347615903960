// For an explanation on policy details, see:
// https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-config-options-fetchPolicy
// and
// https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-config-options-errorPolicy
import { onError } from "apollo-link-error";
import { setContext } from "apollo-link-context";
import { ApolloClient, ApolloLink, DefaultOptions, HttpLink, InMemoryCache } from "@apollo/client";

import * as authService from "./auth";
import { API_BASE_URL, API_CORPORATE_URL } from "../../config/config";

const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: "cache-and-network",
        errorPolicy: "none",
    },
    query: {
        fetchPolicy: "cache-first",
        errorPolicy: "none",
    },
};

//Global error handle for when a user is logged out.  Redirects to login page.
const errorHandlerLink: any = onError((errorHandler: any) => {
    const networkError: any = errorHandler.networkError;

    if (networkError && networkError.statusCode === 401) {

        let event = new Event("authError");
        window.dispatchEvent(event);

        // capture response information for network errors
    } else if (networkError && networkError.response) {
        let body = networkError.bodyText || '';

        // limit length, max size of sentry logging request is 100kB
        if (body.length >= 5000) {
            body = body.substring(0, 5000);
        }
    }
});

/**
 * Adds bearer token to apollo requests.
 *
 * @type {ApolloLink}
 */
const authMiddleware: any = setContext(async (operation) => {
    if (!authService.hasTokenExpired()) {
        // refresh token if it needs refreshing
        if (authService.shouldTokenRefresh()) {
            try {
                await authService.refreshToken();
            } catch (e) {
                // do something
            }
        }
    }

    return {
        credentials: 'include'
    };
});

const authAfterware: any = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
        const { response: { headers } } = operation.getContext();
        if (headers) {
            authService.updateTokenFromHeaders(headers);
        }

        return response;
    });
});

const createClient: any = () => new ApolloClient({
    // By default, this client will send queries to the
    //  `/graphql` endpoint on the same host
    // Pass the configuration option { uri: YOUR_GRAPHQL_API_URL } to the `HttpLink` to connect
    // to a different host
    link: ApolloLink.from(
        [
            authMiddleware,
            authAfterware,
            errorHandlerLink,
            ApolloLink.split(
                (operation) => operation.getContext().clientName === "corporate",
                new HttpLink({ uri: `${API_CORPORATE_URL}/graphql` }),
                new HttpLink({ uri: `${API_BASE_URL}/graphql` }),
            ),
        ]
    ),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
});

export default createClient();
