import React, { memo, useCallback } from 'react';
import { css, SerializedStyles } from '@emotion/react';

import theme from "../../../theme";
import ErrorIcon from "./ErrorIcon";
import { NOOP } from "../../../config/constants";

type Props = {
    name: string,
    value: any,
    [x: string]: any,
    hasError?: boolean,
    id?: string,
    innerRef?: React.RefCallback<HTMLInputElement> | React.Ref<HTMLInputElement>,
    label?: string,
    onChange?: any,
    onEventChange?: (e: InputEvent) => void,
    variant?: 'country' | 'phone number' | 'none',
    hasBorder?: boolean,
    hasIconOnError?: boolean,
    extraContainerStyles?: SerializedStyles,
    onFilterChange?: (e: React.FormEvent<HTMLInputElement>) => void,
    className?: string,
};

const Input: React.FC<Props> = (
    {
        name,
        value = '',
        hasError = false,
        icon = "",
        id = "",
        innerRef = null,
        onChange = NOOP,
        onEventChange = NOOP,
        variant = 'none',
        label = "",
        hasBorder = true,
        hasIconOnError = true,
        className = "",
        extraContainerStyles = null,
        onFilterChange = NOOP,
        ...otherProps
    }
) => {

    const handleChange = useCallback((e: any) => {
        if (onChange && variant !== 'country') {
            onChange(name, e.target.value);
        }

        if (onEventChange) {
            e.persist();
            onEventChange(e);
        }
    }, [name, onChange, onEventChange, variant]);

    const styles = {
        container: css`
            position: relative;
            ${extraContainerStyles};
        `,
        icon: {
            position: "absolute",
            left: 0,
            top: 0,
            height: '100%',
            width: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        input: css`
            border: 0;
            border-bottom: 3px solid ${hasBorder ? theme.forms.defaults.borderColor : theme.colours.transparent};
            border-radius: 0;
            height: 50px;
            padding: 0;
            padding-left: ${!!icon ? "30px" : "0"};
            background: transparent;
            outline: none;
            width: 100%;
            font-size: ${theme.forms.label.fontSize};
            font-family: ${theme.fonts.frutiger};
            font-weight: ${theme.fonts.weights.light};
            ${hasError && theme.forms.error};
            box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px rgba(255, 255, 255, 1);
            ${theme.mixins.placeholder()};
        `,
    };

    const variantToInputType = {
        'country': 'text',
        'phone number': 'tel',
        'none': 'text',
    }

    return (
        <div css={styles.container} className={className}>
            <input
                css={styles.input}
                id={id || name}
                name={name}
                ref={innerRef}
                value={value}
                onChange={handleChange}
                placeholder={label}
                type={variantToInputType[variant]}
                {...otherProps}
            />
            {hasError && hasIconOnError && (
                <ErrorIcon />
            )}
        </div>
    );
};

export default memo(Input);
