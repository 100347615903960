import moment from 'moment';
import { isEmpty } from "lodash";

import { AUTH_REFRESH_LEEWAY } from "./constants";
import LoginError from './LoginError';
import { API_BASE_URL } from "../../config/config";
import { persist, retrieve } from "./localStorage";

export const AUTH_EXPIRY_COOKIE = "X-Auth-Expiry";
export const AUTH_EXPIRY_STORAGE_KEY = 'authExpiry';

/**
 * Should the token be refreshed. Checks if current time is between the after the refresh time. Expiry should
 * also be checked with hasExpired.
 *
 * @returns {boolean|boolean}
 */
export const shouldTokenRefresh: any = () => {
    const expiryCookie = getAuthExpiry();
    // can't refresh if we don't have a token
    if (isEmpty(expiryCookie)) return false;

    const expires = moment(new Date(parseInt(expiryCookie, 10)));
    const refreshTime = expires.clone().subtract(AUTH_REFRESH_LEEWAY, "s");
    const now = moment();

    return now.isSameOrAfter(refreshTime);
};

/**
 * Has the token has expired.
 *
 * @returns {boolean}
 */
export const hasTokenExpired: any = () => {
    const expiryCookie = getAuthExpiry();
    if (isEmpty(expiryCookie)) return true;

    const expires = moment(new Date(parseInt(expiryCookie, 10)));
    const now = moment();

    return now.isAfter(expires);
};

/**
 * Refresh the token. It sends original token and receives an updated token in the headers.
 *
 * @returns {Promise<null>}
 */
export const refreshToken: any = async () => {
    const response = await fetch(`${API_BASE_URL}/auth/refresh`, {
        method: 'POST',
        body: JSON.stringify({ withoutSession: true }),
        credentials: 'include'
    });

    const data = await response.json();

    if (!data.status) {
        throw new Error('Unable to refresh token');
    }
};

/**
 * Login with username/password.
 *
 * @param {string} email
 * @param {string} password
 *
 * @returns {Promise<void>}
 */
export const login: any = async (email: any, password: any) => {
    const response = await fetch(`${API_BASE_URL}/auth/login`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({ email, password, withoutSession: true, domain: 'wtbox.com' }),
        headers: {
            'Content-Type': 'application/json'
        }
    });

    const data = await response.json();

    // handle a redirect for logging into wrong site
    if (data.redirect) {
        window.location = data.redirect;
    }

    if (data.status) {
        updateTokenFromHeaders(response.headers);
    } else {
        // handle a redirect for logging into wrong site
        if (data.link) {
            window.location = data.link;
        } else {
            // error the message
            if (data.message) {
                throw new LoginError(data.message);

                // specific html error message
            } else if (data.htmlMessage) {
                throw new LoginError(data.htmlMessage, 'html', data.link);
            }
        }
    }
    return data;
};

/**
 * Login with social profile.
 *
 * @param {string} email
 * @param {string} token
 * @param {string} type
 *
 * @returns {Promise<void>}
 */
export const socialLogin: any = async (email: string, token: string, type: string) => {
    const body = { email, token, type, withoutSession: true, domain: 'wtbox.com' };

    const response = await fetch(`${API_BASE_URL}/auth/login`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json'
        }
    });

    const data = await response.json();

    // handle a redirect for logging into wrong site
    if (data.redirect) {
        window.location = data.redirect;
    }

    if (data.status) {
        updateTokenFromHeaders(response.headers);
    } else {
        // error the message
        if (data.message) {
            throw new LoginError(data.message);

            // specific html error message
        } else if (data.htmlMessage) {
            throw new LoginError(data.htmlMessage, 'html', data.link);
        }
    }

    return data.redirect;
};

export type SocialLoginType = 'google' | 'facebook';

/**
 * Returns all the cookies that are available. The following cookies are not going to be part of this list:
 * - from other sites
 * - HttpOnly cookies are not accessible
 *
 * @returns {{[p: string]: *}}
 */
export const getCookies = (): { [p: string]: any } =>
    document.cookie.split(";").reduce(
        (ac, cv) =>
            Object.assign(ac, {
                [cv.split("=")[0].trim()]: cv.split("=")[1],
            }),
        {},
    );

export const getCookie = (cookie = "") => getCookies()[cookie];

/**
 * Returns either a cookie or local storage for the token's expiration. Local storage is only set from response header.
 */
export const getAuthExpiry: any = () => {
    return getCookie(AUTH_EXPIRY_COOKIE) || retrieve(AUTH_EXPIRY_STORAGE_KEY);
}

/**
 * Extract the token from the headers and store it.
 *
 * @param headers
 * @returns {null|*}
 */
export const updateTokenFromHeaders: any = (headers: any) => {
    const authExpiryHeader = headers.get(AUTH_EXPIRY_COOKIE);

    if (authExpiryHeader) {
        const existingAuthExpiry = retrieve(AUTH_EXPIRY_STORAGE_KEY);

        // only update if it has changed
        if (isEmpty(existingAuthExpiry) || authExpiryHeader !== existingAuthExpiry) {
            return persist(AUTH_EXPIRY_STORAGE_KEY, authExpiryHeader);
        }
    }

    return null;
};
