import React, { memo } from "react";
import { css } from "@emotion/react";

import theme from "../../../theme";
import errorIcon from "../../../images/icons/error.svg";

type Props = {
    className?: string,
};

const ErrorIcon: React.FC<Props> = ({ className }) => (<div css={errorIconStyle} className={className} />);

const errorIconStyle = css`
    background-image: url('${errorIcon}');
    position: absolute;
    height: 24px;
    width: 24px;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);
    z-index: ${theme.zIndex.zIndexOne};
`;

export default memo(ErrorIcon);