import React, { useCallback, useState } from 'react';
import jwt_decode from "jwt-decode";
import { css } from "@emotion/react";
import { useNavigate, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";

import SignIn from "./SignIn";
import {
    getAuthExpiry,
    socialLogin,
    SocialLoginType,
} from "../../includes/auth";
import * as storage from "../../includes/localStorage";
import Loading from "../utils/Loading";
import LoginError from "../../includes/LoginError";
import theme from "../../../theme";
import { SIGNUP_URL } from "../../../config/config";

const Auth = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const navigate: any = useNavigate();
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const redirectTo = location.state?.redirectTo;

    const redirectAfterAuth = useCallback(() => {
        if (redirectTo) {
            navigate(redirectTo);
        } else {
            if (!isEmpty(getAuthExpiry())) {
                window.location.href = `${SIGNUP_URL}`;
            }
        }
    }, [navigate, redirectTo]);

    const socialSignUp = useCallback((socialProfile: any, socialLoginType: SocialLoginType) => {
        setLoading(true);
        setErrorMessage("");

        let email: string = '';
        let firstName: string = '';
        let lastName: string = '';
        let picture: string = '';
        const credential = socialProfile["credential"];
        if (socialLoginType === "google") {
            const decodedData: any = jwt_decode(credential);
            email = decodedData.email;
            firstName = decodedData.given_name;
            lastName = decodedData.family_name;
            picture = decodedData.picture;
        } else {
            email = socialProfile.email;
        }

        socialLogin(
            email,
            credential,
            socialLoginType
        ).then((redirect: string) => {
            if (!redirect) {
                storage.persist('socialFirstName', firstName);
                storage.persist('socialLastName', lastName);
                storage.persist('socialAvatarUrl', picture);
                redirectAfterAuth();
            }
        }).catch((e: LoginError) => {
            setErrorMessage(e.message);
            setLoading(false);
        });
    }, [redirectAfterAuth]);

    const authContainerStyle = css`
        padding: 0;
        margin: 0;
        position: relative;

        ${theme.breakpoints.down('sm')} {
            width: 100%;
        }
    `;

    return (
        <div css={authContainerStyle}>
            {loading && (<Loading onTop overlay overlayColour={"white"} />)}
            <SignIn
                socialSignUp={socialSignUp}
                errorMessage={errorMessage}
                redirectAfterAuth={redirectAfterAuth}
            />
        </div>
    );
};
export default Auth;
