// Colours - as per http://chir.ag/projects/name-that-color
// Useful alternative Colours https://www.color-blindness.com/color-name-hue

const red = {
    50: '#fff2f2',
    100: '#ffcdd2',
    200: '#ef9a9a',
    300: '#e57373',
    350: '#ff174f',
    400: '#ef5350',
    500: '#f44336',
    600: '#e53935',
    700: '#d32f2f',
    800: '#c62828',
    900: '#b71c1c',
    999: '#ff0000',
};

const green = {
    100: '#C8E6C9',
    200: '#A5D6A7',
    300: '#81C784',
    400: '#66BB6A',
    500: '#4CAF50',
    600: '#43A047',
    700: '#388E3C',
    800: '#2E7D32',
    900: '#1B5E20',
    950: '#145819',
};

const grey = {
    50: '#fafafa',
    80: '#F3F3F3',
    90: '#F4F4F4',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    450: '#AAAAAA',
    500: '#9e9e9e',
    600: '#757575',
    650: '#707070',
    700: '#757575',
    720: '#555555',
    800: '#424242',
    900: '#212121',
    920: '#1B1D28',
};

const blue = {
    50: '#E3F2FD',
    100: '#AAD4EF',
    400: '#1D8BDB',
    450: '#3B528F',
    500: '#05AEF0',
    550: '#5670AB',
    560: '#0076FF',
    600: '#156CBC',
    610: '#0869B2',
    620: '#0964A7',
    700: '#0C4A93',
    900: '#364D9B',
    930: '#222247',
    950: '#3C3E44',
};

const colours = {
    borderColor: grey[300],
    primary: green[900],
    secondary: red[500],
    blue,
    green,
    grey,
    red,
    candyCorn2: '#FCE359',
    chablis: '#fff0f0',
    chateauGreen: '#37B44E',
    cinnabar2: '#E23D44',
    cream: '#FFFFCE',
    crimson: '#ED1C24',
    curiousBlue: '#1D8BDB',
    deepCerulean2: '#066AB2',
    denim: '#156CBC',
    denim2: '#117AC6',
    dodgerBlue: '#2086FC',
    emerald3: '#3ECB54',
    killarney: '#3C763D',
    poloBlue2: '#8DA8D6',
    silverChalice: '#9E9E9E',
    silverChalice2: '#A3A3A3',
    soapStone: '#FFF9F7',
    transparent: 'rgba(0,0,0,0)', // Useful for transitions between no fill and fill.,
    white: '#FFFFFF',
};

const status = {
    success: colours.emerald3,
    error: colours.cinnabar2,
};

/**
 * Convert a hexadecimal integer to a decimal integer.
 *
 * @param {string} hex Hexadecimal integer.
 * @returns {number} Decimal integer.
 */
const hexToInt = (hex: string) => parseInt(parseInt(hex, 16).toString(10));

/**
 * Get an rgba colour from a given 6-digit hex colour and an alpha value (defaults to 1).
 *
 * @param {string} hex 6-digit hex rgb colour.
 * @param {number} alpha Alpha channel value.
 * @returns {string} rgba colour.
 */
const rgba = (hex: string, alpha = 1) => {
    if (!hex || hex.length !== 7) {
        throw Error(`Hex colour must have length of 7, got ${hex}`);
    }

    const rgb = [hex.substring(1, 3), hex.substring(3, 5), hex.substring(5, 7)];

    return `rgba(${hexToInt(rgb[0])}, ${hexToInt(rgb[1])}, ${hexToInt(rgb[2])}, ${alpha})`;
};

const utils = {
    hexToInt,
    rgba,
};

const v = {
    ...colours,
    status,
    utils,
};

export default v;
