import colours from './colours';
import { css } from '@emotion/react';

const popover = {
    container: css`
        background: ${colours.white};
        border-radius: 3px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
            0 1px 1px 0 rgba(0, 0, 0, 0.14),
            0 2px 1px -1px rgba(0, 0, 0, 0.12);
    `
};

export default popover;
