import React from 'react';
import { css, Global } from "@emotion/react";

import Button from "../Button";
import theme from "../../../theme";

const ErrorFallback = () => {
    const containerStyle = css`
        background-color: ${theme.colours.white};
        box-shadow: ${theme.borderAndShadow.boxShadow};
        border-radius: ${theme.borderAndShadow.smallRadius};
        padding: 20px;
        max-width: 600px;
        margin: 100px auto 0 auto;
    `;

    const textStyle = css`
        font-size: 1.6rem;
        font-family: ${theme.fonts.primary};
        margin: 0;
        color: ${theme.fonts.colour};
        text-align: center;
    `;

    const buttonStyle = css`
        margin-top: 30px;
        text-align: center;
    `;

    const bodyStyle = css`
        body {
            background-color: ${theme.colours.grey[100]};
        }
    `;
    return (
        <div css={containerStyle}>
            <Global styles={bodyStyle} />
            <p css={textStyle}>Oops, something has gone wrong.</p>
            <div css={buttonStyle}>
                <Button onClick={() => window.location.reload()} size="lg">Reload</Button>
            </div>
        </div>
    );
};

export default ErrorFallback;
