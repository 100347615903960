import React, { FormEvent, memo, useCallback } from 'react';

const styles = {
    default: {
        margin: '0 auto',
        maxWidth: '600px',
        width: '100%',
    },
    full: {
        maxWidth: '100%'
    }
};

type Props = {
    children: React.ReactNode,
    onSubmit: () => void,
    style?: 'full',
};

const Form: React.FC<Props> = (
    {
        children,
        onSubmit,
        style,
    }
) => {
    /**
     * Handle form submission
     */
    const handleSubmit = useCallback((e: FormEvent) => {
        e.preventDefault();
        onSubmit();
    }, [onSubmit]);

    const css = style ? { ...styles.default, ...styles[style] } : styles.default;

    return (
        <form onSubmit={handleSubmit} css={css}>
            {children}
        </form>
    );
};

export default memo(Form);
