import React from 'react';
import { css } from "@emotion/react";

import logo from '../../images/WTB_logo.png'
import { CORPORATE_URL } from "../../config/config";

const Header = () => (
    <div css={containerStyle}>
        <a href={CORPORATE_URL}>
            <img src={logo} alt={"Writer's Toolbox Logo"} css={imageStyle} />
        </a>
    </div>
);


const containerStyle = css`
    width: 100%;
    height: 87px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 15px #00000029;
    margin-bottom: 20px;
`;

const imageStyle = css`
    height: 28px;
`;

export default Header;
