import { ApolloError } from "@apollo/client";
import { isEmpty } from "lodash"

import errorParser from "./errorParser"

export type ValidationErrorType = {
    [x in string]: string
}

const validationErrorParser = (error: any): ValidationErrorType => {
    const validationErrors: ValidationErrorType = {};
    let message = '';

    if (error instanceof ApolloError && error.graphQLErrors && error.graphQLErrors.length) {
        message = error.graphQLErrors[0].message;

        const category: {
            argumentErrors?: ValidationErrorType
        } = error.graphQLErrors[0]?.extensions?.category || {};
        if (category !== null) {
            const argumentErrors =  category.argumentErrors;
            if (!isEmpty(argumentErrors)) {
                return argumentErrors;
            }
        }
    } else {
        message = errorParser(error);
    }

    validationErrors["errorMessage"] = message;

    return validationErrors;
}

export default validationErrorParser;
