import { TippyProps } from '@tippy.js/react';

export const defaultTippyProps: Partial<TippyProps> = {
    placement: "bottom",
    theme: "tippy-white",
    animation: "scale-subtle",
    inertia: true,
    arrow: true,
};

export default defaultTippyProps;