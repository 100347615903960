import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { css } from "@emotion/react";
import { isEmpty } from "lodash";

import Form from "../form/Form";
import LabelledField from "../form/LabelledField";
import Input from "../form/Input";
import FormActions from "../form/FormActions";
import Button from "../Button";
import useForm, { FieldList } from "../../hooks/useForm";
import { login, SocialLoginType } from "../../includes/auth";
import SocialLogin from "../social/SocialLogin";
import Loading from "../utils/Loading";
import theme from "../../../theme";
import { AUTH_PASSWORD_RECOVERY_URL, getUrl } from "../../includes/routes";
import useElementDimensions from "../../hooks/useElementDimensions";
import { SIGNUP_URL } from "../../../config/config";

type Props = {
    socialSignUp: (user: any, socialLoginType: SocialLoginType) => void,
    redirectAfterAuth: () => void,
    errorMessage?: string,
};

const validator = (fields: FieldList) => {
    const errors: any = {};

    if (!fields.email) {
        errors.email = 'Please provide a email';
    }

    if (!fields.password) {
        errors.password = 'Please provide a password';
    }

    return errors;
};

const SignIn: React.FC<Props> = (
    {
        socialSignUp,
        redirectAfterAuth,
        errorMessage,
    }
) => {
    const [fields, errors, onFieldChange, , validate] = useForm({
        fields: { email: '', password: '' },
        validator,
    });
    const [loginError, setLoginError] = useState<ErrorState>({ isVisible: false, errorMessage: '', isHtml: false });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const redirectTo = location.state?.redirectTo;
    const logInButtonRef = useRef<HTMLButtonElement>(null);
    const logInButtonDimensions = useElementDimensions(logInButtonRef.current);

    useEffect(() => {
        if (!isEmpty(errorMessage)) {
            setLoginError({ isVisible: true, errorMessage: errorMessage || '', isHtml: false });
        }
    }, [errorMessage]);

    const submitSignInForm = () => {
        setLoginError({ isVisible: false, errorMessage: '', isHtml: false });
        if (validate()) {
            setIsSubmitting(true);
            login(fields.email, fields.password).then((data: { redirect: string; }) => {
                if (!data.redirect) {
                    redirectAfterAuth();
                }
            }).catch((e: any) => {
                setIsSubmitting(false);
                setLoginError({ isVisible: true, errorMessage: e.message, isHtml: true });
            });
        }
    }

    const clickForgotPassword = () => {
        navigate(
            getUrl(AUTH_PASSWORD_RECOVERY_URL),
            { state: { redirectTo: redirectTo } }
        )
    }

    const lineContainerStyle = css`
        display: flex;

        ${theme.breakpoints.up("5xl")} {
            margin: 68px 0;
        }

        ${theme.breakpoints.between("xxl", "4xl")} {
            margin-bottom: 38px;
        }

        ${theme.breakpoints.between("lg", "xl")} {
            margin-bottom: 28px;
        }

        ${theme.breakpoints.only('md')} {
            margin-bottom: 21px;
        }

        ${theme.breakpoints.only("sm")} {
            margin-bottom: 25px;
        }

        ${theme.breakpoints.down("xs")} {
            margin-bottom: 18px;
        }
    `;

    const lineTextStyle = css`
        padding: 0 28px;
        margin-top: -8px;
        color: ${theme.colours.silverChalice2};

        ${theme.breakpoints.up("5xl")} {
            font-size: 18px;
            margin-top: -10px;
        }

        ${theme.breakpoints.between("xxl", "4xl")} {
            font-size: 14px;
        }

        ${theme.breakpoints.down("xl")} {
            font-size: 11px;
            margin-top: -6px;
        }
    `;

    const lineStyle = css`
        height: 1px;
        flex-grow: 1;
        background: ${theme.colours.silverChalice2};
    `;

    const authContent = css`
        margin-top: 40px;
        width: 510px;
        position: relative;

        ${theme.breakpoints.down('sm')} {
            width: 100%;
            max-width: 510px;
            margin: 40px auto 0;
        }

        ${theme.breakpoints.down('xs')} {
            padding: 0 20px;
        }
    `;

    const formContainerStyle = css`
        ${theme.breakpoints.up("5xl")} {
            margin-top: 18px;
        }

        ${theme.breakpoints.between("xxl", "4xl")} {
            margin-top: 21px;
        }

        ${theme.breakpoints.between("lg", "xl")} {
            margin-top: 13px;
        }

        ${theme.breakpoints.only("md")} {
            margin-top: 16px;
        }

        ${theme.breakpoints.only("sm")} {
            margin-top: 8px;
        }

        ${theme.breakpoints.down("xs")} {
            margin-top: 6px;
        }
    `;

    const loginErrorSection = css`
        display: ${loginError.isVisible ? 'flex' : 'none'};
        width: 100%;
        background-color: ${theme.colours.chablis};
        border-radius: 3px;
        border: 1px solid ${theme.colours.crimson};
        color: ${theme.colours.red[999]};
        margin-top: 6px;
        margin-bottom: 20px;

        span {
            margin: 10px;
        }
    `;

    const emailInput = css`
        margin-top: 0;
        box-sizing: border-box;
        width: 100%;
    `;

    const passwordInput = css`
        margin-top: 0;
        box-sizing: border-box;
        width: 100%;
    `;

    const signInTextContainer = css`
        ${theme.breakpoints.up("3xl")} {
            margin-top: 67px;

            > div {
                margin-bottom: 22px;
            }
        }

        ${theme.breakpoints.between("lg", "xxl")} {
            margin-top: 53px;
        }

        ${theme.breakpoints.between("sm", "xxl")} {
            > div {
                margin-bottom: 17px;
            }
        }

        ${theme.breakpoints.only("md")} {
            margin-top: 32px;
        }

        ${theme.breakpoints.only("sm")} {
            margin-top: 53px;

        }

        ${theme.breakpoints.down("xs")} {
            margin-top: 46px;

            > div {
                margin-bottom: 28px;
            }
        }
    `;

    const signInText = css`
        font-size: 14px;
        text-align: center;

        a {
            text-decoration: none;
            color: ${theme.colours.blue['400']}
        }

        a:hover {
            text-decoration: underline;
        }

        ${theme.breakpoints.down('sm')} {
            width: 100%;
        }
    `;

    const formActions = css`
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        ${theme.breakpoints.up('sm')} {
            margin-top: 20px;
            justify-content: center;
        }

        ${theme.breakpoints.up("5xl")} {
            margin: 63px 0 68px;
        }

        ${theme.breakpoints.between("xxl", "4xl")} {
            margin: 41px 0 45px;
        }

        ${theme.breakpoints.between("lg", "xl")} {
            margin: 29px 0 34px;
        }

        ${theme.breakpoints.only("md")} {
            margin: 21px 0 26px;
        }

        ${theme.breakpoints.only("sm")} {
            margin: 26px 0 31px;
        }

        ${theme.breakpoints.down("xs")} {
            margin: 19px 0 24px;
        }
    `;

    const customFormActionsStyles = css`
        width: 100%;
        justify-content: center;
    `;

    const formSubmitButton = css`
        background-color: ${theme.colours.blue['400']};
        width: 400px;
        border-radius: 45px;
        height: 40px;
        padding: 0;

        ${theme.breakpoints.down('xs')} {
            width: 250px;
        }
    `;

    const leftHeader = css`
        color: ${theme.colours.curiousBlue};
        font-weight: ${theme.fonts.weights.bold};

        ${theme.breakpoints.up("xxl")} {
            font-size: 50px;
            line-height: 75px;
        }

        ${theme.breakpoints.between("md", "xl")} {
            font-size: 43px;
            line-height: 65px;
        }

        ${theme.breakpoints.down("sm")} {
            font-size: 30px;
            line-height: 45px;
        }
    `;

    const signUpStyle = css`
        display: inline-block;
        color: ${theme.colours.curiousBlue};
        cursor: pointer;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    `;

    return (
        <div css={authContent}>
            {isSubmitting && <Loading onTop overlay overlayColour={"white"} />}
            <div css={leftHeader}>Log in</div>
            <div css={loginErrorSection}>
                {loginError.isHtml ? (
                    <div dangerouslySetInnerHTML={{ __html: loginError.errorMessage }} />
                ) : (
                    <span>{loginError.errorMessage}</span>
                )}
            </div>
            <div css={formContainerStyle}>
                <Form onSubmit={submitSignInForm}>
                    <LabelledField
                        name="email"
                        error={errors.email}
                        label="Email / Username"
                        version={'normal'}
                    >
                        <Input
                            hasError={!!errors.email}
                            css={emailInput}
                            label="Enter email address or username"
                            name="email"
                            onChange={onFieldChange}
                            value={fields.email}
                            hasBorder
                        />
                    </LabelledField>
                    <LabelledField
                        name="password"
                        error={errors.password}
                        label="Password"
                        version={'normal'}
                    >
                        <Input
                            hasError={!!errors.password}
                            css={passwordInput}
                            label="Password"
                            type="password"
                            name="password"
                            onChange={onFieldChange}
                            value={fields.password}
                            hasBorder
                        />
                    </LabelledField>
                    <div css={formActions}>
                        <FormActions customCss={customFormActionsStyles}>
                            <Button
                                css={formSubmitButton}
                                type="submit"
                                forceLoading={isSubmitting}
                                ref={logInButtonRef}
                            >
                                Log in
                            </Button>
                        </FormActions>
                    </div>
                    <div css={lineContainerStyle}>
                        <div css={lineStyle} />
                        <span css={lineTextStyle}>or</span>
                        <div css={lineStyle} />
                    </div>
                    <SocialLogin socialSignUp={socialSignUp} buttonWidth={logInButtonDimensions.width} />
                    <div css={signInTextContainer}>
                        <div css={signInText}>
                            Don't have an account? Check out
                            our <a href={`${SIGNUP_URL}/auth`}>subscriptions</a>.
                        </div>
                        <div css={signInText}>
                            Forgot your password?
                            Please <div onClick={clickForgotPassword} css={signUpStyle}>click here</div>.
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
};


export default SignIn;
