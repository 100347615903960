import React, { forwardRef, memo, useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom'
import { css, SerializedStyles } from '@emotion/react';
import Tippy from '@tippy.js/react';

import Loading from "./utils/Loading";
import { defaultTippyProps } from "../includes/tippy";
import theme from "../../theme";
import { NOOP } from "../../config/constants";

// Only add the odd colour to this.  Generally speaking we should be using "primary" and "secondary" to make
// Redesigns easier.
const getColorMap = {
    green: [theme.colours.green[700], theme.colours.green[800], theme.colours.white],
    primary: [theme.colours.blue[600], theme.colours.blue[620], theme.colours.white],
    red: [theme.colours.red[350], theme.colours.red[400], theme.colours.white],
    secondary: [theme.colours.blue[400], theme.colours.denim2, theme.colours.white],
    yellow: [theme.colours.candyCorn2, theme.colours.cream, theme.colours.curiousBlue],
    cancel: [theme.colours.deepCerulean2, theme.colours.deepCerulean2, theme.colours.white],
};

const getColor = (bordered: boolean, color: Color, theme: any, loading?: boolean) => {
    const [cssColor, hoverCssColor, textColour] = getColorMap[color];

    if (bordered) {
        return css`
            background: transparent;
            border: 1px solid ${cssColor};
            color: ${cssColor};

            ${!loading && css`
                &:hover {
                    background: ${cssColor};
                    color: ${textColour};
                }
            `}
        `;
    } else {
        return css`
            background: ${cssColor};
            border: 0;
            color: ${textColour};

            ${!loading && css`
                &:hover {
                    background: ${hoverCssColor};
                    color: ${textColour};
                }
            `}
        `;
    }
};

const sizes = {
    'sm': '80px',
    'md': '110px',
    'lg': '124px',
    'xxl': '180px',
};

type Color = 'green' | 'primary' | 'red' | 'secondary' | 'yellow' | 'cancel';

type Props = {
    children?: React.ReactNode,
    className?: string,
    bordered?: boolean,
    forceLoading?: boolean,
    disabled?: boolean,
    disabledTooltipMessage?: string,
    color?: Color,
    onClick?: (e: React.MouseEvent) => void,
    type?: 'button' | 'submit' | 'reset' | undefined,
    rounded?: boolean,
    size?: 'sm' | 'md' | 'lg' | 'xxl',
    extraStyles?: SerializedStyles,
    href?: string,
};

const Button = forwardRef<HTMLButtonElement, Props>(({
     children,
     bordered = false,
     forceLoading = false,
     disabled = false,
     disabledTooltipMessage = '',
     className = '',
     color = 'primary',
     onClick = NOOP,
     type = 'button',
     rounded = false,
     size = "lg",
     href = '',
     extraStyles,
}, ref) => {
    const [loading, setLoading] = useState(false);

    const styles = useMemo(() => {
        return css`
            position: relative;
            border-radius: 3px;
            font-size: ${theme.fonts.baseSize};
            font-family: ${theme.fonts.frutiger};
            font-weight: ${theme.fonts.weights.light};
            outline: none;
            padding: 5px 10px;
            white-space: nowrap;
            min-width: ${sizes[size]};
            height: 32px;
            transition: background-color 300ms ease-in-out;
            ${!(disabled || loading || forceLoading) && css`cursor: pointer`};
            ${getColor(bordered || false, color || 'primary', theme, loading || forceLoading)};
            opacity: ${(disabled || loading || forceLoading) ? "0.5" : "1"};

            ${rounded && css`
                border-radius: 16px;
            `}

            ${href && css`
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
            `}

            ${size === 'xxl' && css`
                height: 50px;

                ${rounded && css`
                    border-radius: 25px;
                `}
            `}

            ${extraStyles}
        `;
    }, [bordered, color, extraStyles, loading, forceLoading, disabled, size, href, rounded]);

    const handleClick = useCallback((e: any) => {
        if (onClick !== NOOP) {
            e.preventDefault();
            const clickResult: any = onClick(e);
            if (clickResult instanceof Promise) {
                setLoading(true);
                clickResult.finally(() => {
                    setLoading(false);
                });
            }
        }
    }, [onClick]);

    const isDisabled = disabled || loading || forceLoading;
    const isLoading = loading || forceLoading;
    const loadingNode = useMemo(() => <Loading small overlay={false} ringIndicator onTop />, []);

    const renderButton = useCallback(() => (
        <button
            css={styles}
            className={className}
            type={type}
            onClick={handleClick}
            disabled={isDisabled}
            ref={ref}
        >
            {isLoading && loadingNode}
            {children}
        </button>
    ), [styles, className, type, handleClick, children, isDisabled, isLoading, loadingNode, ref]);

    const renderLink = useCallback(() => {
        const route = href ? href : "#";
        return (
            <div css={linkWrapperStyle(isDisabled)}>
                <Link
                    css={styles}
                    className={className}
                    to={isDisabled ? '#' : route}
                    onClick={onClick}
                >
                    {isLoading && loadingNode}
                    {children}
                </Link>
            </div>
        )
    }, [styles, className, children, href, onClick, isDisabled, isLoading, loadingNode]);

    return (
        <Tippy
            content={disabledTooltipMessage}
            hideOnClick={false}
            enabled={disabled && !!disabledTooltipMessage}
            {...defaultTippyProps}
        >
            {href ? renderLink() : renderButton()}
        </Tippy>
    );
});

const linkWrapperStyle = (disabled?: boolean) => css`
    ${disabled && css`
        pointer-events: none;
    `}
`;

export default memo(Button);
