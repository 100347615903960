import React from 'react';
import { css, SerializedStyles } from "@emotion/react";

import theme from "../../../theme";

type Props = {
    isVisible: boolean,
    message?: string | null,
    customStyle?: SerializedStyles,
    children?: React.ReactNode,
    className?: string,
};

const ErrorMessage: React.FC<Props> = ({ isVisible, message, customStyle, children, className }) => {
    const style = (customStyle?: SerializedStyles) => css`
        display: flex;
        width: 100%;
        margin-top: 10px;
        background-color: ${theme.colours.red['50']};
        border: 1px solid ${theme.colours.crimson};
        border-radius: ${theme.borderAndShadow.smallRadius};
        color: ${theme.colours.crimson};

        ${customStyle};

        span {
            margin: 10px;
        }
    `;

    return (
        <>
            {isVisible && (
                <div id={"errorMessage"} css={style(customStyle)} className={className}>
                    <span>{message || children}</span>
                </div>
            )}
        </>
    );
}


export default ErrorMessage;
