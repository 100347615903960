import React from 'react';
import { Helmet } from "react-helmet";
import { Global, css, ThemeProvider } from '@emotion/react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from "@apollo/client";
import { ErrorBoundary } from "react-error-boundary";

import theme from './theme';
import apolloClient from './core/includes/apollo';
import { getSiteTitle, PUBLIC_URL } from "./config/config";
import Header from "./core/components/Header";
import PageRoutes from "./core/components/PageRoutes";
import ErrorFallback from "./core/components/utils/ErrorFallback";
import { fontImports } from "./theme/fonts";

const styles = css`
    ${fontImports}
    //restore sup and sub.

    sup, sub {
        font-size: smaller;
    }

    sup {
        vertical-align: super;
    }

    sub {
        vertical-align: sub;
    }

    ${theme.tippy.whiteTheme};

    html {
        font-size: ${theme.fonts.baseSize};
        line-height: ${theme.fonts.lineHeight};
        font-family: ${theme.fonts.primary};
        font-weight: ${theme.fonts.weights.light};
    }

    html,
    #root {
        width: 100%;
        height: 100%;
    }

    body {
        margin: 0;
    }

    * {
        box-sizing: border-box;
    }

    .body-hide-content {
        @media (max-width: 1074.92px) {
            height: 100vh;
            overflow: hidden;
        }
    }
`;

function App() {

    return (
        <ApolloProvider client={apolloClient}>
            <ThemeProvider theme={theme}>
                <ErrorBoundary fallback={<ErrorFallback />}>
                    <Helmet>
                        <title>{getSiteTitle('')}</title>
                    </Helmet>

                    <BrowserRouter basename={PUBLIC_URL}>
                        <Global styles={styles} />
                        <Header />
                        <PageRoutes />
                    </BrowserRouter>
                </ErrorBoundary>
            </ThemeProvider>

        </ApolloProvider>
    );
}

export default App;
