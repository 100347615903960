import { useCallback } from 'react';
import { toast, ToastContainerProps } from "react-toastify";

import errorParser from "../includes/errorParser";

const useToast = () => {
    const success = useCallback((message: any) => {
        toast.success(message, defaultToastOptions);
    }, []);

    const error = useCallback((error: any) => {
        const message = errorParser(error);
        toast.error(message, errorToastOptions);
    }, []);

    const info = useCallback((message: any) => {
        toast.info(message, defaultToastOptions);
    }, []);

    return [success, error, info];
};
const toastDuration: number = 5000;

export const defaultToastOptions: ToastContainerProps = {
    position: "top-right",
    autoClose: toastDuration,
    newestOnTop: true,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
};

export const errorToastOptions: ToastContainerProps = {
    position: "top-right",
    autoClose: toastDuration,
    newestOnTop: true,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true
};

export default useToast;
