/**
 * Convert a hex number to an integer.
 *
 * @param {string} hex
 * @returns {number}
 */
export const hexToInt = (hex: string) => parseInt(parseInt(hex, 16).toString(10));

/**
 * Returns rgba string for a hex colour. Colour should include hash at front.
 *
 * @param {string} hex
 * @param {number} alpha
 * @returns {string}
 */
export const rgba = (hex: string, alpha: number) => {
    if (!hex || hex.length !== 7) {
        throw Error(`Hex colour must have length of 7, got ${hex}`);
    }

    const rgb = [hex.substring(1, 3), hex.substring(3, 5), hex.substring(5, 7)];

    return `rgba(${hexToInt(rgb[0])}, ${hexToInt(rgb[1])}, ${hexToInt(rgb[2])}, ${alpha})`;
};

const utils = {
    oneLineEllipsis: `
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    `,
    hexToInt,
    rgba
};

export default utils;
