import React, { useCallback, useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { css } from "@emotion/react";
import { useMutation } from '@apollo/react-hooks';
import { useQuery } from "@apollo/client";
import { isEmpty } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

import Form from "../form/Form";
import LabelledField from "../form/LabelledField";
import Input from "../form/Input";
import FormActions from "../form/FormActions";
import Button from "../Button";
import useForm, { FieldList } from "../../hooks/useForm";
import theme from "../../../theme";
import useToast from "../../hooks/useToast";
import useSetState from "../../hooks/useSetState";
import validationErrorParser, { ValidationErrorType } from "../../includes/validationErrorParser";
import { AUTH_URL, getUrl } from "../../includes/routes";

type Props = {
    token: string
};

type DefaultState = {
    viewMode: boolean,
    isSubmitting: boolean,
    areRequirementsVisible: boolean,
}

const INITIAL_STATE = {
    viewMode: false,
    isSubmitting: false,
    areRequirementsVisible: false,
}

let MIN_PASSWORD_LENGTH = 10;

const NewPassword: React.FC<Props> = ({ token }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const redirectTo = location.state?.redirectTo;
    const [successToast] = useToast();
    const [state, setState] = useSetState<DefaultState>(INITIAL_STATE);
    const { data } = useQuery(GET_MIN_PASSWORD_LENGTH);
    const [changePassword] = useMutation(CHANGE_PASSWORD_QUERY);
    const [passwordMatchError, setPasswordMatchError] = useState('');

    useEffect(() => {
        if (data?.minPasswordLength) {
            MIN_PASSWORD_LENGTH = data?.minPasswordLength;
        }
    }, [data]);

    const [fields, errors, onFieldChange, , validate, setFormErrors] = useForm({
        fields: {
            setPassword: '',
            confirmPassword: '',
        },
        validator,
    });

    const onFocusPassword = useCallback((e: React.MouseEvent) => {
        if (!state.areRequirementsVisible) {
            setState({ areRequirementsVisible: true });
        }
    }, [setState, state.areRequirementsVisible]);

    const submitChangePasswordForm = useCallback(() => {
        if (validate()) {
            setState({ isSubmitting: true });
            changePassword({
                variables: {
                    ...fields,
                    token
                }
            }).then(() => {
                successToast("Password reset is complete.");
                navigate(redirectTo ? redirectTo : getUrl(AUTH_URL));
            }).catch((e: any) => {
                setState({ isSubmitting: false });

                const validationErrors: ValidationErrorType = validationErrorParser(e);
                if (!isEmpty(validationErrors)) {
                    setFormErrors(validationErrors);
                }

                if (validationErrors["errorMessage"]) {
                    setPasswordMatchError(validationErrors["errorMessage"]);
                }
            });
        }
    }, [changePassword, fields, token, validate, setFormErrors, setState, successToast, navigate, redirectTo]);

    const authHeader = css`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: ${theme.colours.grey['90']};
        width: 510px;
        height: 50px;
    `;

    const passwordResetContent = css`
        margin-top: 40px;
        width: 510px;
        position: relative;
    `;

    const leftHeader = css`
        font-size: 26px;
        font-weight: 400;
        margin-left: 20px;
    `;

    const formContainerStyle = css`
        margin-top: 12px;
    `;

    const passwordInput = css`
        margin-top: 0;
        box-sizing: border-box;
        width: 100%;
    `;

    const passwordRequirements = css`
        margin-bottom: 19px;

        p {
            margin-bottom: 10px;
            font-size: 14px;
        }
    `;

    const isPasswordLongEnough = (password: string): boolean => password.length >= 10;

    const passwordRequirement = (password: string, fieldHasError: boolean) => css`
        font-size: 14px;
        list-style: disc inside;
        color: ${password === '' && !fieldHasError ?
                'black' :
                isPasswordLongEnough(password)
                        ? theme.colours.killarney
                        : theme.colours.crimson

        }
    `;

    const formActionsStyle = css`
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 20px;

        & .action-buttons button {
            width: 110px;
            min-width: 100px;
        }
    `;

    const errorMessageStyle = css`
        display: ${passwordMatchError ? 'flex' : 'none'};
        margin-top: ${passwordMatchError ? '10px' : 0};
        width: 100%;
        background-color: ${theme.colours.red['200']};

        span {
            margin: 10px;
        }
    `;


    return (
        <div css={passwordResetContent}>
            <div css={authHeader}>
                <div css={leftHeader}>Please provide a new password</div>
            </div>
            <div css={errorMessageStyle}>
                <span>{passwordMatchError}</span>
            </div>
            <div css={formContainerStyle}>
                <Form onSubmit={submitChangePasswordForm}>
                    <LabelledField name="setPassword" error={errors.setPassword} label="New Password">
                        <Input
                            type="password"
                            hasError={!!errors.setPassword}
                            css={passwordInput}
                            label="Type new password"
                            name="setPassword"
                            onChange={onFieldChange}
                            value={fields.setPassword}
                            onFocus={onFocusPassword}
                        />
                    </LabelledField>
                    {state.areRequirementsVisible && (
                        <div css={passwordRequirements}>
                            <p>Password must:</p>
                            <ul>
                                <li css={passwordRequirement(fields.setPassword, !!errors.setPassword)}>
                                    Have at least {MIN_PASSWORD_LENGTH} characters
                                </li>
                            </ul>
                        </div>
                    )}
                    <LabelledField
                        name="confirmPassword"
                        error={errors.confirmPassword}
                        label="Confirm New Password"
                    >
                        <Input
                            type="password"
                            hasError={!!errors.confirmPassword}
                            css={passwordInput}
                            label="Confirm new password"
                            name="confirmPassword"
                            onChange={onFieldChange}
                            value={fields.confirmPassword}
                        />
                    </LabelledField>
                    <div css={formActionsStyle}>
                        <FormActions>
                            <div className={"action-buttons"}>
                                <Button
                                    type="submit"
                                    forceLoading={state.isSubmitting}
                                >
                                    Save
                                </Button>
                            </div>
                        </FormActions>
                    </div>
                </Form>
            </div>
        </div>
    );
};

const CHANGE_PASSWORD_QUERY: any = gql`
    mutation recoverPasswordHomeUser($setPassword: String!, $confirmPassword: String!, $token: String!) {
        recoverPasswordHomeUser(setPassword: $setPassword, confirmPassword: $confirmPassword, token: $token) {
            id
        }
    }
`;

const GET_MIN_PASSWORD_LENGTH = gql`
    query minPasswordLength {
        minPasswordLength(isStudent: false)
    }
`;

type PasswordError = {
    setPassword?: string,
    confirmPassword?: string
};

const validator = (fields: FieldList) => {
    const errors: PasswordError = {};

    if (!fields.setPassword) {
        errors.setPassword = 'Please provide a new password';
    }

    if (!fields.confirmPassword) {
        errors.confirmPassword = 'Please confirm your new password';
    }

    return errors;
};


export default NewPassword;
