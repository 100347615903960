import React from 'react';
import { Helmet } from "react-helmet";
import { Navigate, Route, Routes } from "react-router-dom";
import { css } from "@emotion/react";

import { getSiteTitle } from "../../config/config";
import Auth from "./auth/Auth";
import theme from "../../theme";
import PasswordRecovery from './auth/PasswordRecovery';
import { AUTH_PASSWORD_RECOVERY_URL, AUTH_URL, getUrl } from "../includes/routes";

const PageRoutes = () => {
    return (
        <div css={routesContainerStyle}>
            <Helmet>
                <title>{getSiteTitle('Log In')}</title>
            </Helmet>
            <Routes>
                <Route path={getUrl(AUTH_URL)} element={<Auth />} />
                <Route path={getUrl(AUTH_PASSWORD_RECOVERY_URL)} element={<PasswordRecovery />} />
                <Route
                    path="*"
                    element={
                        <Navigate to={getUrl(AUTH_URL)} replace={true} />
                    }
                />
            </Routes>
        </div>
    )
}

const routesContainerStyle = css`
    min-height: 611px;
    padding: 0 25% 0 25%;
    display: flex;
    justify-content: center;

    ${theme.breakpoints.down('lg')} {
        padding: 0 15% 0 15%;
    }

    ${theme.breakpoints.down('sm')} {
        padding: 0 10% 0 10%;
    }
`;

export default PageRoutes;
