import React, { useCallback, useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { css } from "@emotion/react";
import { useMutation } from "@apollo/react-hooks";
import OutsideClickHandler from "react-outside-click-handler";
import VerificationInput from "react-verification-input";
import EmailValidator from "email-validator";
import { isEmpty } from "lodash";

import Form from "../form/Form";
import LabelledField from "../form/LabelledField";
import Input from "../form/Input";
import FormActions from "../form/FormActions";
import Button from "../Button";
import useForm, { FieldList } from "../../hooks/useForm";
import Loading from "../utils/Loading";
import theme from "../../../theme";
import NewPassword from "./NewPassword";
import validationErrorParser, { ValidationErrorType } from "../../includes/validationErrorParser";

const verificationCodeLength = 5;
const VERIFICATION_CODE_PLACEHOLDER = "-";
const STEPS = {
    email: "email",
    verification: "verification",
    passwordChange: "passwordChange",
}

const PasswordRecovery: React.FC = () => {
    const [fields, errors, onFieldChange, , validate, setFormErrors] = useForm({
        fields: { email: '', password: '' },
        validator,
    });
    const [recoveryError, setRecoveryError] = useState({ isVisible: false, errorMessage: '' });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [currentStep, setCurrentStep] = useState(STEPS.email)
    const [verificationCode, setVerificationCode] = useState(0);
    const [emailVerificationError, setEmailVerificationError] = useState('');
    const [disableButton, setDisableButton] = useState(true);
    const [passwordRecoveryToken, setPasswordRecoveryToken] = useState('');

    const [setPasswordRecovery] = useMutation(PASSWORD_RECOVERY_EMAIL);
    const [verifyEmailHomeUser] = useMutation(EMAIL_VERIFICATION_QUERY);

    const updateVerificationInputStyle = useCallback(() => {
        const characters = document.querySelectorAll(".vi__character");
        characters.forEach(character => {
            if (character.textContent === VERIFICATION_CODE_PLACEHOLDER && !character.classList.contains("vi__character--selected")) {
                character.classList.add("vi__character--inactive");
            }
        });
    }, []);

    useEffect(() => {
        setDisableButton(verificationCode.toString().length !== verificationCodeLength);
    }, [verificationCode]);

    useEffect(updateVerificationInputStyle, [verificationCode, updateVerificationInputStyle]);

    const submitEmailForm = useCallback(() => {
        setRecoveryError({ isVisible: false, errorMessage: '' });
        if (validate()) {
            setIsSubmitting(true);
            return setPasswordRecovery({
                variables: {
                    email: fields.email
                }
            }).then(() => {
                setCurrentStep(STEPS.verification)
            }).catch((e: any) => {
                setIsSubmitting(false);

                const validationErrors: ValidationErrorType = validationErrorParser(e);
                if (!isEmpty(validationErrors)) {
                    setFormErrors(validationErrors);
                }

                if (validationErrors["errorMessage"]) {
                    setRecoveryError({ isVisible: true, errorMessage: validationErrors["errorMessage"] });
                }
            });
        }
    }, [fields, setPasswordRecovery, validate, setFormErrors]);

    const submitEmailVerificationForm = useCallback(() => {
        setEmailVerificationError('');

        return verifyEmailHomeUser({
            variables: {
                verificationCode,
                email: fields.email
            }
        }).then((response: any) => {
            const token = response.data?.verifyPasswordRecovery;
            setPasswordRecoveryToken(token);
            setCurrentStep(STEPS.passwordChange)
        }).catch((error: any) => {
            const message = error?.graphQLErrors[0].message;
            setEmailVerificationError(message);
        });
    }, [fields.email, verificationCode, verifyEmailHomeUser]);

    useEffect(() => {
        const fn = (event: KeyboardEvent) => {
            if (event.key === 'Enter' && !disableButton) {
                submitEmailVerificationForm();
            }
        };

        window.addEventListener("keypress", fn);

        return () => {
            window.removeEventListener("keypress", fn);
        }
    }, [disableButton, submitEmailVerificationForm]);

    const passwordResetContent = css`
        margin-top: 40px;
        width: 510px;
        position: relative;
    `;

    const formContainerStyle = css`
        margin-top: 12px;
    `;

    const errorSection = css`
        display: ${recoveryError.isVisible ? 'display' : 'none'};
        width: 100%;
        background-color: ${theme.colours.red['200']};

        span {
            margin: 10px;
        }
    `;

    const emailInput = css`
        margin-top: 0;
        box-sizing: border-box;
        width: 100%;
    `;

    const formActions = css`
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    `;

    const formSubmitButton = css`
        background-color: ${theme.colours.blue['400']};
        width: 124px;
    `;

    const authHeader = css`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: ${theme.colours.grey['90']};
        width: 510px;
        height: 50px;
    `;

    const leftHeader = css`
        font-size: 26px;
        margin-left: 20px;
        font-weight: 400;
    `;

    const emailVerificationText = css`
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 14px;

        span {
            font-size: 16px;
        }
    `;

    const emailVerificationErrorStyle = css`
        display: ${emailVerificationError ? 'flex' : 'none'};
        width: 100%;
        background-color: ${theme.colours.red['200']};

        span {
            margin: 10px;
        }
    `;

    const emailVerificationForm = css`
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .vi__container {
            height: 60px;
            margin-bottom: 20px;
        }

        .vi__character {
            border: 1px solid ${theme.colours.grey[450]};
            border-radius: 3px;
            margin-top: 10px;
            padding: 0 14px;
            background: transparent;
            outline: none;
            line-height: 56px;
            min-width: 56px;
            width: 56px;
            height: 60px;
            margin-right: 10px;
            text-align: center;
            font-family: ${theme.fonts.frutiger};
            font-size: 46px;
            font-weight: 400;
            display: block;

            &--inactive {
                color: ${theme.colours.grey[300]};
            }

            &--selected {
                border: 1px solid ${theme.colours.blue[400]};
                color: ${theme.colours.blue[400]};
            }
        }
    `;

    return (
        <>
            {currentStep === STEPS.email && (
                <div css={passwordResetContent}>
                    {isSubmitting && <Loading onTop overlay overlayColour={"white"} />}
                    <div css={authHeader}>
                        <div css={leftHeader}>Please provide your email</div>
                    </div>
                    <div css={errorSection}>
                        <span>{recoveryError.errorMessage}</span>
                    </div>
                    <div css={formContainerStyle}>
                        <Form onSubmit={submitEmailForm}>
                            <LabelledField name="email" error={errors.email} label="Email">
                                <Input
                                    hasError={!!errors.email}
                                    css={emailInput}
                                    label="Enter email address"
                                    name="email"
                                    onChange={onFieldChange}
                                    value={fields.email}
                                />
                            </LabelledField>
                            <div css={formActions}>
                                <FormActions>
                                    <Button
                                        css={formSubmitButton}
                                        type="submit"
                                        forceLoading={isSubmitting}
                                    >
                                        Continue
                                    </Button>
                                </FormActions>
                            </div>
                        </Form>
                    </div>
                </div>
            )}
            {currentStep === STEPS.verification && (
                <div css={passwordResetContent}>
                    <div css={emailVerificationText}>
                        <span>
                            A verification code has been sent to <strong>{fields.email}</strong>.
                            Please check your inbox and enter the code here.
                        </span>
                    </div>
                    <div css={emailVerificationErrorStyle}>
                        <span>{emailVerificationError}</span>
                    </div>
                    <div css={emailVerificationForm}>
                        <OutsideClickHandler onOutsideClick={updateVerificationInputStyle}>
                            <VerificationInput
                                length={5}
                                validChars="0-9"
                                placeholder={VERIFICATION_CODE_PLACEHOLDER}
                                removeDefaultStyles
                                container={{ className: "verification-container" }}
                                characters={{ className: "verification-characters" }}
                                character={{
                                    className: "verification-character",
                                    classNameInactive: "verification-character--inactive",
                                    classNameSelected: "verification-character--selected"
                                }}
                                onChange={setVerificationCode}
                            />
                        </OutsideClickHandler>
                    </div>
                    <div css={formActions}>
                        <FormActions>
                            <Button
                                css={formSubmitButton}
                                type="submit"
                                disabled={disableButton}
                                onClick={submitEmailVerificationForm}
                            >
                                Continue
                            </Button>
                        </FormActions>
                    </div>
                </div>
            )}
            {currentStep === STEPS.passwordChange && (
                <NewPassword token={passwordRecoveryToken} />
            )}
        </>
    )
};

const validator = (fields: FieldList, isSubmit: boolean = false) => {
    const errors: any = {};

    if (!fields.email) {
        errors.email = 'Please provide an email address';
    }

    if (isSubmit && fields.email && !EmailValidator.validate(fields.email)) {
        errors.email = 'This is not a valid email address'
    }

    return errors;
};

const PASSWORD_RECOVERY_EMAIL: any = gql`
    mutation setPasswordRecoveryEmail($email: String!) {
        setPasswordRecoveryEmail(email: $email)
    }
`;

const EMAIL_VERIFICATION_QUERY: any = gql`
    mutation verifyPasswordRecovery($verificationCode: String!, $email: String!) {
        verifyPasswordRecovery(verificationCode: $verificationCode, email: $email)
    }
`;

export default PasswordRecovery;
