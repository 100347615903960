import React, { useMemo } from "react";
import { css, keyframes } from "@emotion/react";

import theme from "../../../theme";

type Props = {
    overlay?: boolean,
    ringIndicator?: boolean,
    small?: boolean,
    onTop?: boolean,
    fitToScreen?: boolean,
    overlayColour?: string,
    delay?: number,
};

const Loading: React.FC<Props> = (
    {
        ringIndicator = false,
        onTop,
        overlay = false,
        fitToScreen = false,
        overlayColour = "black",
        small = false,
        delay = 0,
    }
) => {

    const loadingStyle = useMemo(() => {
        if (onTop) {
            return () => (css`
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                display: flex;
                animation: ${delayKeyframe} ${delay}ms;
            `);
        }
        return () => (css`
            width: 100%;
            height: 50px;
            position: relative;
            animation: ${delayKeyframe} ${delay}ms;
        `);
    }, [onTop, delay]);

    const loadingRingStyle = useMemo(() => {
        return () => css`
            display: inline-block;
            position: relative;
            width: ${small ? 30 : 64}px;
            height: ${small ? 30 : 64}px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        `;
    }, [small]);

    const overlayStyle = useMemo(() => {
        if (fitToScreen) {
            return (theme: any) => (css`
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0.25;
                z-index: ${theme.zIndex.zIndexHigh - 1};
                background-color: ${overlayColour};
            `);
        }

        return (theme: any) => (css`
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0.25;
            z-index: ${theme.zIndex.zIndexHigh - 1};
            background-color: ${overlayColour};
        `);
    }, [fitToScreen, overlayColour]);

    const loadingContentStyle = useMemo(() => {
        return (theme: any) => (css`
            position: ${fitToScreen ? "fixed" : "absolute"};
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: ${theme.zIndex.zIndexHigh}; // Ensure visibility
        `);
    }, [fitToScreen]);

    return (
        <div css={loadingStyle}>
            {overlay && (
                <div css={overlayStyle} />
            )}
            {ringIndicator ? (
                <div css={loadingRingStyle}>
                    <div css={loadingRingSectionStyle(small)} />
                    <div css={loadingRingSectionStyle(small)} />
                    <div css={loadingRingSectionStyle(small)} />
                    <div css={loadingRingSectionStyle(small)} />
                </div>
            ) : (
                <div css={loadingContentStyle}>
                    <div css={loadingDotStyle(1)}>w</div>
                    <div css={loadingDotStyle(2)}>t</div>
                    <div css={loadingDotStyle(3)}>b</div>
                </div>
            )}
        </div>
    );
}

const delayKeyframe = keyframes`
    0% {
        visibility: hidden;
        position: fixed;
    }

    99.9% {
        visibility: hidden;
        position: fixed;
    }

    100% {
        visibility: visible;
        position: inherit;
    }
`;

const ringLoadingKeyframe = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const bloopLoadingKeyframe = keyframes`
    50% {
        transform: scale(1.3) translateY(-5px);
        filter: brightness(1);
    }
`;

const loadingRingSectionStyle = (small?: boolean) => (theme: any) => css`
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 80%;
    height: 80%;
    margin: 9%;
    border: ${small ? 4 : 6}px solid ${theme.colours.white};
    border-radius: 50%;
    animation: ${ringLoadingKeyframe} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${theme.colours.white} transparent transparent transparent;

    :nth-of-type(N+1) {
        animation-delay: -0.45s;
    }

    :nth-of-type(N+2) {
        animation-delay: -0.3s;
    }

    :nth-of-type(N+3) {
        animation-delay: -0.15s;
    }
`;

const dot1 = css`
    background-color: ${theme.colours.denim2};
`;

const dot2 = css`
    background-color: ${theme.colours.denim2};
    animation-delay: 0.15s;
    margin-left: 9px;
`;

const dot3 = css`
    background-color: ${theme.colours.denim2};
    animation-delay: 0.3s;
    margin-left: 9px;
`;

const loadingDotStyle = (dotIndex: number) => {

    const dotStyle = (() => {
        switch (dotIndex) {
            case 1:
                return dot1;
            case 2:
                return dot2;
            case 3:
                return dot3;
            default:
                return "";
        }
    });

    return css`
        width: 16px;
        height: 16px;
        border-radius: 50%;
        font-size: 12px;
        line-height: 16px;
        color: ${theme.colours.white};
        display: inline-block;
        text-align: center;
        font-family: ${theme.fonts.frutiger};
        animation: 1.5s ${bloopLoadingKeyframe} ease-in-out infinite;

        ${dotStyle()};
    `;
}

export default Loading;
